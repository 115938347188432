<template>
  <section class="m-better-product m-scroll" ref="scroll"  @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/order/icon-fanhui@2x.png" alt="back">
      <span>优选好货</span>
      <router-link to="/zh/cart" >
        <img   src="@/assets/order/icon_gouwu@2x.png" alt="img">
        <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
      </router-link>
    </div>

    <!-- 滚动切换 -->
    <van-tabs :offset-top="tabTop" title-active-color="#ED2A24" title-inactive-color="#000000" v-model="activeSectionId"
    style="position:sticky;top:0px;z-index:99">
      <van-tab  :title="item.title" v-for="(item, index) in classifyList" :key="index" :name="item.id" />
    </van-tabs>
    <goods-columns @cartClick="handleCart" :data="goodsList" :columns="classifyList[activeIndex].row_pro_num" style="margin-top:15px"></goods-columns>
    <p class="search-no" v-if='noMore'>暂无更多商品</p>  
    
    <!-- <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading> -->

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MDot         from '@/components/zh/m-dot.vue'
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { getBetterClassify, getBetterClassifyGoods } from '@/api/zh/better.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{   MDot, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop:0,
      tab:0,          // tabs选中的面板
      recomnd:4,
      skuModal:false,
      tabTop:44,
      dotAnimate:false,
      start:false,
      start_dom:null,
      cartNum:0,
      scrollTop:false,
      swipeImgs:[
          '//img.alicdn.com/imgextra/i2/226880098/O1CN01plSH9a1CavPOuMZmm_!!0-saturn_solar.jpg_220x220.jpg_.webp',
          '//img.alicdn.com/imgextra/i3/1253260119/O1CN01XiAXfW1CkXk4EQ2qe_!!0-saturn_solar.jpg_220x220.jpg_.webp',
          '//img.alicdn.com/imgextra/i4/110340780/O1CN01hrmXWB1HdHWxKnizR_!!0-saturn_solar.jpg_220x220.jpg_.webp',
          '//img.alicdn.com/imgextra/i3/730320029/O1CN01EyMkhZ1C5K5sFdlpj_!!0-saturn_solar.jpg_220x220.jpg_.webp',
      ],
      swipeBg1: '//img.alicdn.com/imgextra/i3/730320029/O1CN01EyMkhZ1C5K5sFdlpj_!!0-saturn_solar.jpg_220x220.jpg_.webp',
      swipeBg2: '//img.alicdn.com/imgextra/i3/1253260119/O1CN01XiAXfW1CkXk4EQ2qe_!!0-saturn_solar.jpg_220x220.jpg_.webp',
      goodsList: [],
      classifyList: [{}],
      page: 1,
      activeSectionId: '',
      loading: false,
      islock: false,
      noMore: false,
      goodsId: '',
      activeIndex: 0
    }
  },
  mounted(){
    this.getBetterClassifyHandle()

    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth
    localStorage.setItem('_back', '/')
  },

  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getBetterClassifyGoodsHandle()
        }
      }
    },
    handleBack(){
      this.$router.go(-1)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getBetterClassifyHandle() {
      // 获取分类
      getBetterClassify(this.$route.query.id).then(res => {
        if(res) {
          this.classifyList = res.data.filter(item => {
            return item.status == 1
          })
          // if(res.data.length > 0) {
          //   this.activeSectionId = this.classifyList[0].id
          // }
        }
      })
    },
    getBetterClassifyGoodsHandle() {
      // 获取优选好货分类下的商品
      this.islock = true
      getBetterClassifyGoods({type_id: this.activeSectionId, page: this.page, cid: this.$route.query.id}).then(res => {
        this.islock   = false
        if(res) {
          this.goodsList = this.goodsList.concat(res.data.data)
        }else{
          this.noMore = true
        }
      }).finally(() => {
        this.loading  = false
      })
    }
  },
  watch: {
    activeSectionId(val) {
      if(val) {
        this.classifyList.forEach((el, index) => {
          if(el.id == val) {
            this.activeIndex = index
          }
        })
        this.goodsList = []
        this.page = 1
        this.islock   = false
        this.noMore = false
        this.getBetterClassifyGoodsHandle()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search-no {
  line-height:80px;
  text-align:center;
  color:#888;
  font-size:14px;
}
@import './list.less';
</style>