/** 
 *   优选好货
 */
import { get } from '@/untils/js/axios.js'

// 优选好货分类
export const getBetterClassify = id => get(`/special/type-list/${id}`)

// 优选好货分类
export const getBetterClassifyGoods = params => get('/special/goods-list', params)
